import instance from "./axios"
export const createGlobal = (data: object) => {
  return instance.post("/globals/createGlobal", data)
}
export const getGlobals = (data?: object) => {
  return instance.get("/globals/getGlobals", { params: data })
}
export const getGlobal = (data?: object) => {
  return instance.get("/globals/getGlobal", { params: data })
}
export const updateGlobal = (id: string, data?: object) => {
  return instance.post("/globals/updateGlobal", { globalId: id, ...data })
}
export const deleteGlobal = (id: string) => {
  return instance.post("/globals/deleteGlobal", { globalId: id })
}
