import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { IPreset } from "../../components/presets"

// Define a type for the slice state
export interface IThemeState {
  menuStyle?: "transparent" | "dark" | "white"
  menuStyleColor?: string
  menuStyleBgColor?: string
  primaryColor?: string

  // layout
  /** current media screen whethe is Mobile size */
  isMobile?: boolean
  /** layout: "side" | "top" | "mix" */
  layout?: "side" | "top" | "mix"
  /** whether fix header */
  fixedHeader?: boolean
  /** @deprecated */
  stickyHeader?: boolean
  /** @deprecated */
  fixedSider?: boolean
  contentWidth?: "fluid" | "fixed"

  // state
  collapsed?: boolean
  isFullscreen?: boolean

  // display
  showTags?: boolean
  showCollapse?: boolean
  showLogo?: boolean
  showBread?: boolean
  showBreadIcon?: boolean

  showHeader?: boolean
  showSider?: boolean
  showFooter?: boolean

  // other
  keepAlive?: boolean // if open page keep alive .
  animateName?: IPreset

  /** algorithm preset */
  algorithm?: "default" | "dark" | "compact"
}

// Define the initial state using that type
const initialState: IThemeState = {
  menuStyle: "white",
  menuStyleColor: "#FFFFFFA6",
  menuStyleBgColor: "#00152a",
  primaryColor: "#2cc203",
  layout: "side",
  showTags: true,
  fixedHeader: true,
  showCollapse: true,
  contentWidth: "fluid",
  collapsed: false,
  showLogo: true,
  showBread: true,
  showBreadIcon: true,
  animateName: "scaleInBottom",
  algorithm: "default",
  isMobile: false,
}

export const slice = createSlice({
  name: "theme",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<IThemeState>) {
      const newState = { ...initialState, ...action.payload }
      for (const key in newState) {
        state[key] = newState[key]
      }
    },
  },
})

export const ThemeActions = slice.actions
export const ThemeReducer = slice.reducer
