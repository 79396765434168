// var need to start with VITE
// export default {
//   instance: {
//     baseURL: import.meta.env["VITE_SERVER_BASE_URL"],
//   },
// }

const dev = {
  instance: {
    baseURL: "http://localhost:3303/api/v1/manage",
  },
}

const main = {
  instance: {
    baseURL: "/api/v1/manage",
  },
}

const EnvConfig = import.meta.env.DEV ? dev : main

export default EnvConfig
