// https://ant.design/docs/react/migration-v5-cn
import "antd/dist/reset.css" // basic style offered by antd
import "./index.css"

import React from "react"
import { createRoot } from "react-dom/client"

import App from "./App"

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
