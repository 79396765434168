import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable"
import { ArrowClockwise, ArrowLineRight, ArrowsInLineHorizontal, CaretDown, X, XSquare } from "@phosphor-icons/react"
import type { MenuProps } from "antd"
import { Dropdown, Tag } from "antd"
import { useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { SortableItem } from "../../components/common/Sort"
import type { IRoute } from "../../router"
import { useTags, useTheme } from "../../store"

const iconSize = 22
const menuIconSize = 20

export default function Tags() {
  const { theme } = useTheme()
  const { pathname, state } = useLocation()
  const { tags, setTags } = useTags()
  const navigate = useNavigate()

  const [selectedTag, setSelectedTag] = useState<IRoute>() // tag show action menu

  const activeTag = tags.find((i) => i.path == pathname) // tag active

  const handleCloseTag = (tag: IRoute, tagIndex: number) => {
    const newTags = tags.filter((i) => i.path != tag.path)
    setTags(newTags)
    // chooseNewTag(newTags, tag, tagIndex)
    if (tag.path == pathname) {
      const nextRoute = tags[tagIndex + 1]?.path || newTags[newTags.length - 1]?.path || "/"
      navigate(nextRoute)
    }
  }

  const menuItems: MenuProps["items"] = useMemo(() => {
    return [
      { label: "Reload", icon: <ArrowClockwise size={menuIconSize} />, key: "reload" },
      !selectedTag?.affix && { label: "Close", icon: <X size={menuIconSize} />, key: "close" },
      { label: "Close Others", icon: <ArrowsInLineHorizontal size={menuIconSize} />, key: "closeOthers" },
      { label: "Close to the Right", icon: <ArrowLineRight size={menuIconSize} />, key: "closeToTheRight" },
      { label: "Close All", icon: <XSquare size={menuIconSize} />, key: "closeAll" },
    ].filter(Boolean)
  }, [selectedTag])

  const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

  const handleClickMenuItem = (key: string | number) => {
    if (!selectedTag?.path && !activeTag?.path) {
      return
    }

    const exactTag = selectedTag || activeTag
    const tagIndex = tags.findIndex((tag) => tag.path == exactTag.path)

    let newTags = tags
    if (key == "reload") {
      return navigate("/redirect" + pathname, { state, replace: true })
    } else if (key == "close") {
      newTags = tags.filter((tag, index) => index != tagIndex)
      setTags(newTags)
      const nextRoute = newTags[tagIndex]?.path || newTags[newTags.length - 1]?.path || "/"
      navigate(nextRoute)
    } else if (key == "closeOthers") {
      newTags = tags.filter((tag, index) => index == tagIndex || tag.affix)
      setTags(newTags)
      const nextRoute = newTags[newTags.length - 1]?.path || "/"
      navigate(nextRoute)
    } else if (key == "closeToTheRight") {
      newTags = [...tags.slice(0, tagIndex + 1), ...tags.slice(tagIndex + 1).filter((tag) => tag.affix)]
      setTags(newTags)
      if (newTags.find((i) => i.path == pathname)) {
        return
      }
      const nextRoute = newTags[newTags.length - 1]?.path || "/"
      navigate(nextRoute)
    } else if (key == "closeAll") {
      newTags = tags.filter((tag) => tag.affix)
      setTags(newTags)
      const nextRoute = newTags[newTags.length - 1]?.path || "/"
      navigate(nextRoute)
    }
    console.log(newTags, "newTags")
  }

  function handleDragEnd(event) {
    console.log("drag end")
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = tags.findIndex((item) => item.path == active.id)
      const newIndex = tags.findIndex((item) => item.path == over.id)
      const newTags = arrayMove(tags, oldIndex, newIndex)
      setTags(newTags)
    }
  }

  return (
    <div className="flex h-8 items-center justify-between  border-0 border-y-[1px] border-solid border-y-[#d6d6d6] bg-white p-0">
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext
          strategy={horizontalListSortingStrategy}
          items={tags.filter((i) => i.path).map((item) => ({ ...item, id: item.path }))}
        >
          {/* sortable */}
          <div className="no-scrollbar flex items-center overflow-x-scroll">
            {tags.map((tag, index) => {
              return (
                <SortableItem key={tag.path} id={tag.path}>
                  <Dropdown
                    onOpenChange={(open) => setSelectedTag(open ? tag : null)}
                    trigger={["contextMenu"]}
                    menu={{ onClick: ({ key }) => handleClickMenuItem(key), items: menuItems }}
                  >
                    <Tag
                      key={tag.path}
                      onClick={() => navigate(tag.path)}
                      onClose={() => handleCloseTag(tag, index)}
                      color={pathname == tag.path && theme.primaryColor}
                      closable={!tag.affix}
                      className="z-[10001] m-1 cursor-pointer select-none"
                    >
                      {tag.title}
                    </Tag>
                  </Dropdown>
                </SortableItem>
              )
            })}
          </div>
        </SortableContext>
      </DndContext>

      {/* tab actions */}
      <div className="flex h-full items-center">
        <div
          onClick={() => handleClickMenuItem("reload")}
          className="flex h-full items-center border-0 border-l-[1px] border-solid border-[#d6d6d6] px-2"
        >
          <ArrowClockwise className=" cursor-pointer" size={iconSize} />
        </div>
        <Dropdown
          trigger={["click"]}
          className=" whitespace-nowrap "
          placement="bottomRight"
          onOpenChange={(open) => setSelectedTag(open ? tags.find((i) => i.path == pathname) : null)}
          menu={{ onClick: ({ key }) => handleClickMenuItem(key), items: menuItems }}
        >
          <div className="flex h-full items-center border-0 border-l-[1px] border-solid border-[#d6d6d6] px-2">
            <CaretDown className=" cursor-pointer" size={iconSize} />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}
