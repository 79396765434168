export default {
  RESET: "Reset",
  SEARCH: "Search",
  STATUS: "Status",
  NAME: "Name",
  DESC: "Desc.",
  USERNAME: "Username",
  PHONE: "Phone",
  PASSWORD: "Password",
  ACTION: "Action",
  EDIT: "Edit",
  ADD: "Add",
  DELETE: "Delete",
  DETAIL: "Detail",
  ROLE: "Role",
  TIME: "Time",
  REMARK: "Remark",
  LOCATION: "Location",
  ACCOUNT: "Account",
  AVATAR: "Avatar",
  NICKNAME: "Nickname",
  PROFILE: "Profile",
  SAVE: "Save",
  RESET_PASSWORD: "Reset Password",
  THEME_SETTING: "Theme Setting",
  LOG_OUT: "Log out",
  SECURITY: "Security",
  SECURITY_SETTING: "Security Setting",
  SITE_SETTING: "Site Setting",
  SITE_TITLE: "Site Title",
  SITE_LOGO: "Site Logo",
  OPEN: "Open",
  CLOSE: "Close",
  DICT_SETTING: "Dict Setting",
  NEW: "New",
  CREATED_AT: "Created At",
}
