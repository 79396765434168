import { Gauge, Gear, Printer, User } from "@phosphor-icons/react"
import { lazy } from "react"

import i18n from "../config/locales"
import PageView from "../layouts/layoutComponents/pageView"
import NotFound from "../pages/404"
import { SuspenseWrapper } from "./lazy"
const { t } = i18n

const LoginLogger = SuspenseWrapper(lazy(() => import("../pages/logger/LoginLogger")))
const OperationLogInfo = SuspenseWrapper(lazy(() => import("../pages/logger/OperationLogInfo")))
const FileLogInfo = SuspenseWrapper(lazy(() => import("../pages/logger/FileLogInfo")))
const DashBoard = SuspenseWrapper(lazy(() => import("../pages/dashboard/index")))
const StaffInfo = SuspenseWrapper(lazy(() => import("../pages/staff/StaffInfo")))
const RoleInfo = SuspenseWrapper(lazy(() => import("../pages/staff/RoleInfo")))
const WhiteList = SuspenseWrapper(lazy(() => import("../pages/setting/WhiteList")))
const GLobalInfo = SuspenseWrapper(lazy(() => import("../pages/global/GlobalInfo")))
const AccountSetting = SuspenseWrapper(lazy(() => import("../pages/setting/AccountSetting")))

export const routes = [
  {
    path: "/dashboard",
    title: t("router.DASHBOARD"),
    element: <DashBoard />,
    icon: <Gauge />,
    affix: true,
    keepAlive: true,
    children: [{ title: t("router.DASHBOARD"), path: "/dashboard/console", element: <DashBoard />, keepAlive: true }],
  },

  {
    path: "/staff",
    title: t("router.STAFF_MANAGE"),
    element: <PageView />,
    icon: <User />,
    children: [
      { path: "/staff/staffInfo", title: t("router.STAFF_LIST"), element: <StaffInfo />, keepAlive: true },
      { path: "/staff/roleInfo", title: t("router.ROLE_LIST"), element: <RoleInfo />, keepAlive: true },
    ],
  },
  {
    path: "/log",
    title: t("router.LOGGER_MANAGE"),
    element: <PageView />,
    icon: <Printer />,
    children: [
      {
        path: "/log/operationLog",
        title: t("router.LOGGER_OPERATION"),
        element: <OperationLogInfo />,
        keepAlive: true,
      },
      { path: "/log/loginLog", title: t("router.LOGGER_LOGIN"), element: <LoginLogger />, keepAlive: true },
      { path: "/log/fileLog", title: t("router.LOGGER_FILE"), element: <FileLogInfo />, keepAlive: true },
    ],
  },
  {
    path: "/setting",
    title: t("router.SYSTEM_SETTING"),
    element: <PageView />,
    icon: <Gear />,
    children: [
      { path: "/setting/whiteList", title: t("router.WHITE_LIST"), element: <WhiteList />, keepAlive: true },
      { path: "/setting/global", title: t("router.GLOBAL_SETTING"), element: <GLobalInfo />, keepAlive: true },
      { path: "/setting/account", title: t("router.ACCOUNT_SETTING"), element: <AccountSetting />, keepAlive: true },
    ],
  },
  { path: "*", title: "Not Found", hidden: true, element: <NotFound /> },
]
