import { generateRandomString } from "@packages/utils"
import { message } from "antd"
import type { AxiosRequestConfig } from "axios"
import axios from "axios"
import CryptJs from "crypto-js"
import forge from "node-forge"

import config from "../../config/env"
import { refreshToken } from "../staff"

const instance = axios.create({
  baseURL: config.instance.baseURL,
  timeout: 10000,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Encrypt = (config: AxiosRequestConfig) => {
  if (config.url != "/public/getRsaPublcKey" && ["post"].includes(config.method)) {
    const AES_KEY = generateRandomString(16)
    const RSA_PUBLIC_KEY = localStorage.getItem("RSA_PUBLIC_KEY")?.replace(/\\n|\\r/g, "")

    // RSA_PUBLIC_KEY encrpt AES_KEY
    try {
      // RSA encrypt AES_KEY
      const publicKey = forge.pki.publicKeyFromPem(RSA_PUBLIC_KEY)
      const ENCRYPTED_AES_KEY = publicKey.encrypt(AES_KEY)

      // AES encrypt data
      console.log("config.data", config.data, JSON.stringify(config.data))
      const encryptedBody = CryptJs.AES.encrypt(JSON.stringify(config.data), AES_KEY).toString()

      config.headers["X-Encrypted-AES-Key"] = JSON.stringify(ENCRYPTED_AES_KEY)
      config.data = { encryptedBody }
    } catch (error) {
      console.log(error, 1111222233)
    }
  }
}

// const Decrypt = (config: AxiosRequestConfig) => {}

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }

    console.log("config.url", config.url, config.method)
    // Encrypt(config)

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

let isRefreshing = false

const requests = []

instance.interceptors.response.use(
  (response) => {
    const { method } = response.config
    if (["post", "put", "delete", "patch"].includes(method)) {
      message.destroy()
      message.success("Success!")
    }

    console.log("response", response.config.headers)

    try {
      const ENCRYPTED_AES_KEY = JSON.parse(response.config.headers["X-Encrypted-AES-Key".toLocaleLowerCase()] as string)
      console.log("ENCRYPTED_AES_KEY", ENCRYPTED_AES_KEY)
    } catch (error) {
      console.log(error)
    }

    return response.data
  },
  async (error) => {
    console.log("error", error)
    const status = error?.response?.status
    const originalRequestConfig = error.config
    if (status === 401) {
      if (originalRequestConfig.url === "/staffs/refreshToken") {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        window.location.reload()
        return Promise.reject(error)
      }
      /**
       * refresh token
       * re-send the initial request
       */
      // cache the request
      if (isRefreshing) {
        return new Promise((resolve) => {
          requests.push(() => {
            resolve(instance(originalRequestConfig))
          })
        })
      } else {
        isRefreshing = true
        return refreshToken()
          .then(async (res: any) => {
            localStorage.setItem("accessToken", res.accessToken)
            requests.forEach((cb) => cb?.())
            return instance(originalRequestConfig) // re-send initial request
          })
          .catch((error) => {
            localStorage.removeItem("accessToken")
            window.location.reload()
            return Promise.reject(error)
          })
          .finally(() => {
            isRefreshing = false
          })
      }
    }

    const info: { code?: number; message?: string } = {}
    if (!error?.response) {
      info.code = 500
      info.message = "Network Error"
    } else {
      info.code = error?.response.data?.code || 500
      info.message = error?.response.data?.message || "Internal Server Error"
    }
    message.destroy()
    message.error(info.message)
    return Promise.reject(info)
  },
)
export default instance
