import {
  ArrowsIn,
  ArrowsOut,
  Bell,
  EyeSlash,
  Gear,
  GithubLogo,
  Globe,
  MagnifyingGlass,
  Question,
  SignOut,
  User,
  UserCircle,
} from "@phosphor-icons/react"
import type { MenuProps, ModalProps } from "antd"
import { Badge, Dropdown, Image, Input, Modal, Tooltip } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ChangeLanguage from "../../components/common/ChangeLanguage"
import ResetPasswordModal from "../../components/common/ResetPasswordModal"
import { useTheme, useUser } from "../../store"
import Setting from "./setting"

const iconSize = 26

export default function Avatar() {
  const { user, setUser } = useUser()
  const { theme, setTheme } = useTheme()
  const { menuStyleBgColor, menuStyle, menuStyleColor, layout, isFullscreen } = theme

  const [showSearch, setShowSearch] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)

  const [resetPasswordModal, setResetPasswordModal] = useState<ModalProps>({})

  const { t } = useTranslation()

  const navigate = useNavigate()

  const Logout = async () => {
    localStorage.clear()
    window.location.reload()
    setUser(null)
  }

  const MenuItems: MenuProps["items"] = [
    // { label: "User Profile", key: "user" },
    {
      label: (
        <a onClick={() => setShowDrawer(true)} className=" flex  items-center gap-1">
          <Gear size={22} />
          {t("THEME_SETTING")}
        </a>
      ),
      key: "theme",
    },
    {
      label: (
        <a
          onClick={() => setResetPasswordModal({ ...resetPasswordModal, open: true })}
          className=" flex  items-center gap-1"
        >
          <EyeSlash size={22} />
          {t("RESET_PASSWORD")}
        </a>
      ),
      key: "password",
    },
    {
      label: (
        <a onClick={() => navigate("/setting/account")} className=" flex  items-center gap-1">
          <User size={22} />
          {t("router.ACCOUNT_SETTING")}
        </a>
      ),
      key: "account",
    },
    {
      label: (
        <a
          className=" flex  items-center gap-1"
          onClick={() =>
            Modal.confirm({
              title: "Hint",
              content: "Are you sure you want to logout?",
              onOk: async () => {
                await Logout()
                window.location.reload()
              },
            })
          }
        >
          <SignOut size={22} />
          {t("LOG_OUT")}
        </a>
      ),
      key: "logout",
    },
  ]
  return (
    <>
      <Setting setShowDrawer={setShowDrawer} open={showDrawer} onClose={() => setShowDrawer(false)} />

      <div
        style={{
          ...(["top"].includes(layout) && {
            ...(menuStyle == "transparent" && {
              backgroundColor: "transparent",
            }),
            ...(menuStyle == "dark" && {
              backgroundColor: menuStyleBgColor,
              color: menuStyleColor,
            }),
            ...(menuStyle == "white" && {
              backgroundColor: "white",
            }),
          }),
        }}
        className="flex h-full cursor-pointer items-center"
      >
        <div className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]">
          {showSearch && <Input autoFocus suffix={<MagnifyingGlass />} onBlur={() => setShowSearch(false)} />}
          {!showSearch && <MagnifyingGlass size={iconSize} onClick={() => setShowSearch(true)} />}
        </div>

        <Tooltip title="GitHub" autoAdjustOverflow>
          <div
            onClick={() => window.open("https://github.com/sanjayheaven/gganbu-admin-FE")}
            className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]"
          >
            <GithubLogo size={iconSize} />
          </div>
        </Tooltip>

        <Tooltip title="Help" autoAdjustOverflow>
          <div
            onClick={() => window.open("https://sanjayheaven.github.io/gganbu-admin-docs")}
            className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]"
          >
            <Question size={iconSize} />
          </div>
        </Tooltip>

        <Tooltip title={(theme.isFullscreen && "Reduction") || "Full Screen"} autoAdjustOverflow>
          <div
            onClick={() => setTheme({ ...theme, isFullscreen: !isFullscreen })}
            className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]"
          >
            {(theme.isFullscreen && <ArrowsIn size={iconSize} />) || <ArrowsOut size={iconSize} />}
          </div>
        </Tooltip>

        <Tooltip title="Notificaitons" autoAdjustOverflow>
          <div className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]">
            <Badge overflowCount={10} count={199}>
              <Bell size={iconSize} />
            </Badge>
          </div>
        </Tooltip>

        <ChangeLanguage>
          <div className="flex h-full items-center px-2 hover:bg-[rgb(0,0,0,0.025)]">
            <Globe size={iconSize} />
          </div>
        </ChangeLanguage>

        <Dropdown trigger={["hover"]} menu={{ items: MenuItems }}>
          <div className="flex items-center px-2 hover:bg-[rgb(0,0,0,0.025)]">
            {user?.avatar ? (
              <div className=" flex max-h-12 max-w-12 items-center overflow-hidden">
                <Image preview={false} src={user?.avatar} width={48} />
              </div>
            ) : (
              <UserCircle size={48} color="#00000040" />
            )}
            {/* <AvatarAnt size="large" icon={<User />} /> */}
            <div className=" ml-3 max-w-[80px] truncate">{user?.name}</div>
          </div>
        </Dropdown>
      </div>

      <ResetPasswordModal
        {...resetPasswordModal}
        userId={user._id}
        onCancel={() => setResetPasswordModal({ ...resetPasswordModal, open: false })}
      />
    </>
  )
}
