import "nprogress/nprogress.css"
import "./config/antd"

import { useFavicon, useTitle } from "ahooks"
import zhCN from "antd/es/locale/zh_CN"
import { done, start } from "nprogress" // 进度条插件
import { useEffect } from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, matchRoutes, useLocation, useNavigate, useRoutes } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"

import * as GlobalApi from "./api/global"
import * as PublickApi from "./api/public"
import * as StaffApi from "./api/staff"
import { localLanguage } from "./config/locales"
import { AntdConfigProvider } from "./context"
import type { IRoute } from "./router"
import { ConstantRoutes, createAuthRoutes, createRoutesWrapper, routes } from "./router"
import store, { persistor, useGlobal, useTags, useUser } from "./store"

function useSiteSetting() {
  const { global } = useGlobal()
  useTitle(global.siteTitle || "")
  useFavicon(global.siteLogo || "")
  return {}
}
/**
 * here is the entry of router.
 * according to the auth to set the accessible route
 */
const RouterAuth = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { tags, setTags } = useTags()
  const { user, setUser } = useUser()
  const { setGlobal } = useGlobal()

  const routeAuth = user?.roleInfo?.routeAuth || []
  const username = user?.username

  const authRoutes = username != "admin" ? createAuthRoutes(routeAuth) : routes

  const authRoutesWrapper = createRoutesWrapper(authRoutes)
  const Element = useRoutes(authRoutesWrapper as any)

  const { pathname } = location

  /** set site title favicon desc */
  useSiteSetting()

  const getStaffByToken = async () => {
    try {
      const res: any = await StaffApi.getStaffByToken()
      setUser(res)
    } catch (error) {
      localStorage.removeItem("accessToken")
      navigate("/login")
      console.log(error)
    }
  }

  const getGlobalSetting = async () => {
    try {
      const res: any = await GlobalApi.getGlobal()
      setGlobal(res)
    } catch (error) {
      console.log(error)
    }
  }

  const routerBeforeEach = async () => {
    try {
      start()

      if (!localStorage.getItem("accessToken")) {
        // 常量的白名单
        if (ConstantRoutes.find((item) => item.path == pathname)) {
          return
        }
        return navigate("/login")
      }
      if (!user._id) {
        await getStaffByToken()
      }
      const matched = matchRoutes(authRoutesWrapper as any, location)
      if (!matched) {
        return navigate("/")
      }

      const matchRoute: IRoute = matched[matched.length - 1]?.route
      if (!matchRoute.hidden && !tags.find((i) => i.path == matchRoute.path)) {
        setTags([...tags, matchRoute])
      }
    } catch (error) {
      console.log(error)
    } finally {
      done()
    }
  }

  useEffect(() => {
    routerBeforeEach()
  }, [location.pathname])

  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    if (token && user._id) {
      getStaffByToken()
      getGlobalSetting()
    }
  }, [])

  /**
   * if local has no loginToken, then need to login
   */
  return Element
}

// const getRsaPublcKey = async () => {
//   try {
//     const res = await PublickApi.getRsaPublcKey()
//     localStorage.setItem("RSA_PUBLIC_KEY", res)
//   } catch (error) {
//     console.log(error)
//   }
// }

// getRsaPublcKey()

export default function App() {
  const getRsaPublcKey = async () => {
    try {
      const res = await PublickApi.getRsaPublcKey()
      localStorage.setItem("RSA_PUBLIC_KEY", JSON.stringify(res))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getRsaPublcKey()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AntdConfigProvider locale={(localLanguage === "zh" && zhCN) || undefined}>
          <div className="h-screen">
            <Router>
              <RouterAuth />
            </Router>
          </div>
        </AntdConfigProvider>
      </PersistGate>
    </Provider>
  )
}
