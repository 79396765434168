import { ConfigProvider, theme as algorithmTheme } from "antd"
import type { ConfigProviderProps } from "antd/lib/config-provider"

import { getPopupContainer } from "../config/antd"
import { useTheme } from "../store"

export const AntdConfigProvider = ({ children, locale, theme: antTheme }: ConfigProviderProps) => {
  const { theme } = useTheme()
  const { algorithm = algorithmTheme.defaultAlgorithm } = antTheme || {}
  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: theme.primaryColor }, algorithm }}
      locale={locale}
      getPopupContainer={getPopupContainer}
    >
      {children}
    </ConfigProvider>
  )
}
