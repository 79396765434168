import { ArrowsClockwise, Copy, Gear, X } from "@phosphor-icons/react"
import { Checkbox, ColorPicker, Divider, Drawer, Popconfirm, Radio, Select, Switch, Tooltip } from "antd"
import { Button, type DrawerProps, message } from "antd"
import copy from "copy-to-clipboard"
import { useMemo, useState } from "react"

import { presetMotionOptions } from "../../components/presets"
import { useTheme } from "../../store"
import { primaryColorOptions } from "./utils"

export default function Setting({
  setShowDrawer,
  ...props
}: {
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
} & DrawerProps) {
  const { theme, setTheme } = useTheme()

  const {
    primaryColor,
    layout,
    fixedHeader,
    showTags,
    showCollapse,
    contentWidth,
    collapsed,
    showLogo,
    showBread,
    showBreadIcon,

    menuStyle,
    animateName,
  } = theme

  const [pickerColor] = useState(primaryColor)
  const width = 400 // drawe width also transform distance of config button

  const disableItem = useMemo(() => {
    const { layout, showBread } = theme
    return {
      showCollapse: layout == "top",
      collapsed: layout == "top",
      // showBread: layout == "top",
      showBreadIcon: !showBread,
    }
  }, [theme])

  const displayCheckOptions = [
    { label: "Tags Bar", value: "showTags" },
    {
      label: "Sidebar Collapse",
      value: "showCollapse",
      disabled: disableItem.showCollapse,
    },
    { label: "Logo", value: "showLogo" },
    { label: "BreadCrumb", value: "showBread" },
    {
      label: "BreadCrumb Icon",
      value: "showBreadIcon",
      disabled: disableItem.showBreadIcon,
    },
  ]
  return (
    <>
      {(props.open || import.meta.env.DEV) && (
        <div
          style={{
            position: "fixed",
            top: "calc(50% - 24px)",
            zIndex: 10001,
            backgroundColor: primaryColor,
            borderRadius: "4px 0 0 4px",
            right: (!props.open && "0px") || width,
            transition: "all 0.3s cubic-bezier(.23,1,.32,1),box-shadow .3s cubic-bezier(.23,1,.32,1)",
          }}
          onClick={() => setShowDrawer(!props.open)}
          className="flex h-12 w-12 cursor-pointer items-center justify-center text-white"
        >
          {(!props.open && <Gear size={20} />) || <X size={20} />}
        </div>
      )}

      <Drawer closable={false} width={width} placement="right" {...props}>
        <Divider>
          <h3>Layout</h3>
        </Divider>

        <div className="mb-8 grid grid-cols-3 items-center justify-items-center">
          {/* side */}
          <div
            onClick={() => setTheme({ ...theme, layout: "side" })}
            className="flex h-12 w-16 cursor-pointer items-center rounded"
            style={{ boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 18%)" }}
          >
            <div className="h-full w-4 rounded-l bg-black"></div>
            <div className="relative h-full w-12">
              <div className="absolute top-0 h-3 w-full rounded-tr bg-white"></div>
              <div className="absolute bottom-0 flex h-9 w-full items-center justify-center rounded-br bg-slate-200">
                <span
                  style={{
                    ...(layout == "side" && {
                      color: primaryColor,
                      padding: 2,
                      border: `1px solid ${primaryColor}`,
                      borderRadius: "50%",
                    }),
                  }}
                >
                  side
                </span>
              </div>
            </div>
          </div>
          {/* top */}
          <div
            onClick={() => setTheme({ ...theme, layout: "top" })}
            className="h-12 w-16 cursor-pointer rounded"
            style={{ boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 18%)" }}
          >
            <div className=" rounded-br">
              <div className="h-3 w-16 rounded-t bg-white"></div>
              <div className="flex h-9 w-16 items-center justify-center rounded-b bg-slate-200">
                <span
                  style={{
                    ...(layout == "top" && {
                      color: primaryColor,
                      padding: 2,
                      border: `1px solid ${primaryColor}`,
                      borderRadius: "50%",
                    }),
                  }}
                >
                  top
                </span>
              </div>
            </div>
          </div>
          {/* mix */}
          <div
            onClick={() => setTheme({ ...theme, layout: "mix" })}
            className="h-12 w-16 cursor-pointer rounded"
            style={{ boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 18%)" }}
          >
            <div className="top-0 h-3 w-full rounded-t bg-white"></div>
            <div className="flex h-9 items-center">
              <div className="h-full w-4 rounded-bl bg-black"></div>
              <div className="flex h-full w-12 items-center justify-center rounded-br bg-slate-200">
                <span
                  style={{
                    ...(layout == "mix" && {
                      color: primaryColor,
                      padding: 2,
                      border: `1px solid ${primaryColor}`,
                      borderRadius: "50%",
                    }),
                  }}
                >
                  mix
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4 flex items-center justify-between">
          <div>Fixed Header</div>
          <Switch checked={fixedHeader} onChange={(value) => setTheme({ ...theme, fixedHeader: value })} />
        </div>

        <div className="mb-4 flex items-center justify-between">
          <div>Content Width</div>
          <Radio.Group value={contentWidth} onChange={(e) => setTheme({ ...theme, contentWidth: e.target.value })}>
            <Radio value="fluid">Fluid</Radio>
            <Radio value="fixed">Fixed</Radio>
          </Radio.Group>
        </div>

        <div className="mb-4 flex items-center justify-between">
          <div>Collapsed </div>
          <Switch
            disabled={disableItem.collapsed}
            checked={collapsed}
            onChange={(value) => setTheme({ ...theme, collapsed: value })}
          />
        </div>

        {/* <Divider>
          <h3>Theme</h3>
        </Divider>

        <div className=" flex items-center justify-center">
          <Radio.Group
            value={theme.algorithm}
            buttonStyle="solid"
            className="m-auto"
            onChange={(e) => setTheme({ ...theme, algorithm: e.target.value })}
          >
            <Radio value="default">Default</Radio>
            <Radio value="dark">Dark</Radio>
            <Radio value="compact">Dark</Radio>
          </Radio.Group>
        </div> */}

        <Divider>
          <h3>Menu Style</h3>
        </Divider>

        <div className="mb-4 flex w-full items-center justify-between">
          <Radio.Group
            value={menuStyle}
            buttonStyle="solid"
            className="m-auto"
            onChange={(e) => setTheme({ ...theme, menuStyle: e.target.value })}
          >
            <Radio.Button value="dark">Dark</Radio.Button>
            <Radio.Button value="transparent">Transparent</Radio.Button>
            <Radio.Button value="white">White</Radio.Button>
          </Radio.Group>
        </div>

        {/* <Switch
          checked={ menuStyle === "dark"}
          onChange={(value) =>
            setTheme({ ...theme, menuStyle: (value && "dark") || "light" })
          }
          checkedChildren="Dark"
          unCheckedChildren="Light"
        /> */}

        <Divider>
          <h3>Color</h3>
        </Divider>

        <div className="m-auto flex w-full flex-wrap items-center justify-center gap-2">
          {primaryColorOptions.map((item) => {
            return (
              <Tooltip key={item.title || item.color} title={item.title || item.color}>
                <div
                  onClick={() => setTheme({ ...theme, primaryColor: item.color })}
                  style={{ backgroundColor: item.color }}
                  className=" h-5 w-5 cursor-pointer"
                >
                  {/* {item.color} */}
                </div>
              </Tooltip>
            )
          })}

          <ColorPicker
            size="small"
            value={pickerColor}
            onChangeComplete={(v) => setTheme({ ...theme, primaryColor: v.toHexString() })}
          />
        </div>

        <Divider>
          <h3>Display</h3>
        </Divider>

        <Checkbox.Group
          className="m-0 w-full"
          value={[
            showTags && "showTags",
            showCollapse && "showCollapse",
            showLogo && "showLogo",
            showBread && "showBread",
            showBreadIcon && "showBreadIcon",
          ]}
          onChange={(checked: string[]) => {
            setTheme({
              ...theme,
              showTags: checked.includes("showTags"),
              showCollapse: checked.includes("showCollapse"),
              showLogo: checked.includes("showLogo"),
              showBread: checked.includes("showBread"),
              showBreadIcon: (checked.includes("showBread") && checked.includes("showBreadIcon")) || false,
            })
          }}
        >
          <div className="grid grid-cols-2 items-center gap-2">
            {displayCheckOptions.map((item) => {
              return (
                <Checkbox
                  key={item.label}
                  style={{ margin: 0 }}
                  disabled={item.disabled}
                  value={item.value}
                  className="m-0"
                >
                  {item.label}
                </Checkbox>
              )
            })}
          </div>
        </Checkbox.Group>

        <Divider>
          <h3>Transition Animation</h3>
        </Divider>

        <Select
          value={animateName}
          onChange={(value) => setTheme({ ...theme, animateName: value })}
          showSearch
          className=" mb-4 w-full"
          options={presetMotionOptions.map((item) => {
            return { label: item.label, value: item.value }
          })}
        />

        <Button
          className=" mb-4 w-full"
          onClick={() => {
            copy(JSON.stringify(theme))
            message.success("Copied! ")
          }}
          block
        >
          <div className=" flex items-center justify-center gap-2">
            <Copy size={20} />
            <div>Copy</div>
          </div>
        </Button>

        <Popconfirm title={"Are you sure to reset theme setting?"} onConfirm={() => setTheme({})}>
          <Button type="primary" block>
            <div className=" flex items-center justify-center gap-2">
              <ArrowsClockwise size={20} />
              <div>Reset</div>
            </div>
          </Button>
        </Popconfirm>
      </Drawer>
    </>
  )
}
