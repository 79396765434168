export default {
  RESET: "重置",
  SEARCH: "搜索",
  STATUS: "状态",
  NAME: "名称",
  DESC: "描述",
  USERNAME: "用户名",
  PHONE: "手机号",
  PASSWORD: "密码",
  ACTION: "操作",
  EDIT: "编辑",
  ADD: "新增",
  DELETE: "删除",
  DETAIL: "详情",
  ROLE: "角色",
  TIME: "时间",
  REMARK: "备注",
  LOCATION: "地址",
  ACCOUNT: "账户",
  AVATAR: "头像",
  NICKNAME: "昵称",
  PROFILE: "个人资料",
  SAVE: "保存",
  RESET_PASSWORD: "重置密码",
  THEME_SETTING: "主题设置",
  LOG_OUT: "登出",
  SECURITY: "安全",
  SECURITY_SETTING: "安全设置",
  SITE_SETTING: "站点设置",
  SITE_TITLE: "网站标题",
  SITE_LOGO: "网站logo",
  OPEN: "打开",
  CLOSE: "关闭",
  DICT_SETTING: "字典设置",
  NEW: "新建",
  CREATED_AT: "创建时间",
}
