import type { DragEndEvent } from "@dnd-kit/core"
import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core"
import {
  arrayMove,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import type { ReactNode } from "react"

export function SortableItem(props: { id: string; children: ReactNode; className?: string }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div className={props.className || ""} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  )
}

interface ISortProps<T = unknown> {
  data: Record<string, string>[]
  renderItem: (item: T, index?: number) => JSX.Element
  keyName?: string
  x?: boolean
  y?: boolean
  onChange?: (data: T[]) => void
  disabled?: boolean
  className?: string
  itemClassName?: string
}

export default function Sort(props: ISortProps) {
  const { disabled, data, renderItem, keyName = "_id", x = false, y = true, onChange, itemClassName = "" } = props
  const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

  function handleDragEnd(event: DragEndEvent) {
    console.log("drag end")
    const { active, over } = event
    if (active?.id !== over?.id) {
      const oldIndex = data.findIndex((item) => item[keyName] == active?.id)
      const newIndex = data.findIndex((item) => item[keyName] == over?.id)
      const newArray = arrayMove(data, oldIndex, newIndex)
      console.log(newArray)
      onChange?.(newArray)
    }
  }
  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        disabled={disabled}
        strategy={x ? horizontalListSortingStrategy : y ? verticalListSortingStrategy : rectSortingStrategy}
        items={data.map((d) => ({ ...d, id: d[keyName] }))}
      >
        {data.map((item, index) => {
          return (
            <SortableItem className={itemClassName} key={item[keyName]} id={item[keyName]}>
              {renderItem?.(item, index)}
            </SortableItem>
          )
        })}
      </SortableContext>
    </DndContext>
  )
}
