import { GLobalActions } from "../features/global"
import { TagActions } from "../features/tag"
import { ThemeActions } from "../features/theme"
import { UserActions } from "../features/user"
import { useAppDispatch, useAppSelector } from "./_app"

export * from "./_app"

export function useGlobal() {
  const global = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()

  const setGlobal = (data: typeof global) => {
    dispatch(GLobalActions.setGlobal(data))
  }
  return { global, setGlobal }
}

export function useUser() {
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  const setUser = (data: typeof user) => {
    dispatch(UserActions.setUser(data))
  }
  return { user, setUser }
}

export function useTheme() {
  const theme = useAppSelector((state) => state.theme)
  const dispatch = useAppDispatch()

  const setTheme = (data: typeof theme) => {
    dispatch(ThemeActions.setTheme(data))
  }
  return { theme, setTheme }
}

export function useTags() {
  const tags = useAppSelector((state) => state.tag.tags)
  const dispatch = useAppDispatch()

  const setTags = (data: typeof tags) => {
    dispatch(TagActions.setTags(data))
  }
  return { tags, setTags }
}
