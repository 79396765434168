import { List, User, X } from "@phosphor-icons/react"
import type { DrawerProps, LayoutProps, MenuProps } from "antd"
import { Avatar, Drawer, Dropdown, Layout, Modal, Typography } from "antd"
import type { CSSProperties } from "react"
import { useState } from "react"

import { useTheme, useUser } from "../../store"
import { Menu, Tags } from "../components"
import type { ILayoutStyle } from "../utils"

export default function MobileHeader({
  headerWrapperStyle,
  headerStyle,
}: {
  headerWrapperStyle?: CSSProperties
  headerStyle?: CSSProperties
} & LayoutProps) {
  const { setUser } = useUser()
  const { theme } = useTheme()
  const commonHeaderWrapperStyle: ILayoutStyle["headerWrapperStyle"] = {
    zIndex: 50,
    ...(theme.fixedHeader && { boxShadow: " 0 1px 4px 0 rgb(0 21 41 / 12%)" }),
  }

  const [drawer, setDrawer] = useState<DrawerProps>({ placement: "left" })
  const Logout = async () => {
    localStorage.clear()
    window.location.reload()
    setUser({})
  }

  const MenuItems: MenuProps["items"] = [
    {
      label: (
        <Typography.Text
          className=" cursor-pointer"
          onClick={() =>
            Modal.confirm({
              // parentContext:,

              title: "Hint",
              content: "Are you sure you want to logout?",
              onOk: async () => {
                await Logout()
                window.location.reload()
              },
            })
          }
        >
          Log Out
        </Typography.Text>
      ),
      key: "logout",
    },
  ]

  return (
    <>
      <div style={{ ...headerWrapperStyle, ...commonHeaderWrapperStyle }}>
        <Layout.Header style={{ ...headerStyle }}>
          <List onClick={() => setDrawer({ ...drawer, open: true })} size={32} className="mx-4" />

          <Dropdown trigger={["hover"]} menu={{ items: MenuItems }}>
            <div className="mx-4 flex items-center">
              <Avatar size="large" icon={<User />} />
            </div>
          </Dropdown>
        </Layout.Header>

        <>{theme.showTags && <Tags />}</>
      </div>

      <Drawer
        {...drawer}
        closable={false}
        onClose={() => setDrawer({ ...drawer, open: false })}
        bodyStyle={{ padding: 0 }}
      >
        <div className=" border-0 border-b-[1px] border-solid border-b-[#0505050f] px-5 py-4 ">
          <div className=" flex min-w-full items-center justify-between">
            <div className="text-3xl font-bold">Gganbu</div>
            <X size={32} onClick={() => setDrawer({ ...drawer, open: false })} />
          </div>
        </div>
        <Menu mode="inline" onClick={() => setDrawer({ ...drawer, open: false })} onOpenChange={undefined} />
      </Drawer>
    </>
  )
}
