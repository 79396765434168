import { useNavigate } from "react-router-dom"

import { useGlobal, useTheme } from "../../store"

export default function Logo() {
  const { theme } = useTheme()
  const { layout, collapsed, showLogo, menuStyle, menuStyleBgColor, menuStyleColor } = theme
  const { global } = useGlobal()

  const navigate = useNavigate()
  return (
    <div
      className="flex h-16 cursor-pointer items-center justify-center bg-white transition-all"
      onClick={() => navigate("/")}
      style={{
        // transition: "all 0.2s",
        minWidth: 200,
        ...(layout == "side" && {
          ...(collapsed && { minWidth: 0 }),
        }),
        ...(["side", "top"].includes(layout) && {
          ...(menuStyle == "transparent" && {
            backgroundColor: "transparent",
          }),
          ...(menuStyle == "dark" && {
            backgroundColor: menuStyleBgColor,
            color: menuStyleColor,
          }),
          ...(menuStyle == "white" && {
            backgroundColor: "white",
          }),
        }),
      }}
    >
      {showLogo && (
        <>
          {/* <img src={LogoImg} alt="logo" className="w-10 h-10" /> */}
          <>
            <>
              {["mix", "top"].includes(layout) && (
                <div className="mx-2 max-w-full truncate text-3xl font-bold">{global.siteTitle}</div>
              )}
            </>
            <>
              {layout == "side" && (
                <>
                  {!collapsed && <div className="mx-2 max-w-full truncate text-3xl font-bold">{global.siteTitle}</div>}
                </>
              )}
            </>
          </>
        </>
      )}
    </div>
  )
}
